import bachelorette from './images/bachelorette.png';
import intellectual from './images/intellectual.png';
import luxury from './images/luxury.png';
import sports from './images/sports.png';
import nature from './images/nature.png';
import value from './images/value.png';

export const guides = [
	{ guideId: 1, code: 'bachelorette', label: 'Bachelorette' },
	{ guideId: 2, code: 'intellectual', label: 'Intellectual' },
	{ guideId: 3, code: 'luxury', label: 'Luxury Expert' },
	{ guideId: 4, code: 'sports', label: 'Sports Fan' },
	{ guideId: 5, code: 'nature', label: 'Nature Lover' },
	{ guideId: 6, code: 'value', label: 'Value Seeker' },
];

export const guideImages = {
	bachelorette,
	intellectual,
	luxury,
	sports,
	nature,
	value,
};
