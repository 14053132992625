import React from 'react';
import './FitGauge.css';

const FitGauge = ({ label, fitDecimal, fitPhrase }) => {
    return (
        <div className="fit-gauge">
            <div className="fit-gauge-label">{label}</div>
			<div className="fit-gauge-bar">
                <div className="fit-gauge-fill" style={{ width: `${fitDecimal * 100}%` }}></div>
            </div>
            <div className="fit-gauge-phrase">{fitPhrase}</div>
        </div>
    );
};

export default FitGauge;
