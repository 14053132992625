import React, { useState, useEffect } from 'react';
import './MenuBar.css';

import logo from './images/city-sync-logo.png';
// import logo from './images/city-slice-logo.png';
import TabSelector from './tabSelector/TabSelector';
import LocationSelector from './locationSelector/LocationSelector';
import GuideSelector from './guideSelector/GuideSelector';

const MenuBar = ({ selectedTab, setSelectedTab, selectedLocation, setSelectedLocation, selectedGuides, setSelectedGuides }) => {

	const [lastScrollTop, setLastScrollTop] = useState(0);
	const [tabSelectorStyle, setTabSelectorStyle] = useState({ bottom: '0px' });

	useEffect(() => {
		function handleScroll() {
			const scrollTop = document.documentElement.scrollTop;

			// Debugging outputs
			console.log("Current ScrollTop:", scrollTop);
			console.log("Last ScrollTop:", lastScrollTop);
			console.log("Current TabSelectorStyle:", tabSelectorStyle);

			if (scrollTop > lastScrollTop) { 
				// Scrolling down
				console.log("Scrolling down...");
				setTabSelectorStyle({ bottom: '-100px' }); 
			} else {
				// Scrolling up
				console.log("Scrolling up...");
				setTabSelectorStyle({ bottom: '0px' });
			}
			setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [tabSelectorStyle, lastScrollTop]);

	return (
		<div className="menuBar" id="menuBar">
			<div className="logo">
				<img src={logo} alt="logo" className="logo" />
			</div>
			<div className="tabSelector" style={tabSelectorStyle}>
				<TabSelector selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
			</div>
			<div className="locationGuide">
				<div className="locationSelector">
					<LocationSelector selectedTab={selectedLocation} setSelectedTab={setSelectedLocation} />
				</div>
				<div className="guideSelector">
					<GuideSelector selectedGuides={selectedGuides} setSelectedGuides={setSelectedGuides} />
				</div>
			</div>
		</div>
	);
};

export default MenuBar;