import React from 'react';
import FitGauge from '../../../common/fitGauge/FitGauge';
import './ProductInfo.css'

function ProductInfo({ productDetail }) {
	const mapPriceRangeToDollarSigns = (priceRange) => {
		switch (priceRange) {
			case 'cheap':
			return '$';
			case 'moderate':
			return '$$';
			case 'expensive':
			return '$$$';
			case 'very expensive':
			return '$$$$';
			default:
			return '';
		}
	};
	
	return (
		<div className="product-info">
			<img src={productDetail.logoUrl} alt={productDetail.productName} className="logo" />
			
			<p className="attributes">
				{productDetail.categoryName} • {mapPriceRangeToDollarSigns(productDetail.priceRange)} •{' '}
				{productDetail.neighborhoodName}
			</p>
		
			<p className="summary">{productDetail.summary}</p>
		
			<div className="fits">
				{productDetail.guideFits &&
					productDetail.guideFits.map((fit, index) => (
						<FitGauge key={index} label={fit.guideLabel} fitDecimal={fit.fitDecimal} fitPhrase={fit.fitPhrase} />
					))}
			</div>
		</div>
		);
	}
	
	export default ProductInfo;
	