// src/tabs/search/SearchTab.js

import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import config from '../../utility/config';  
import './SearchTab.css';
import ProductTile from '../../product/productTile/ProductTile';
import clearIcon from './images/xmark-circle.png';

const SearchTab = ({ selectedGuides, onSelectProduct, selectedProductId }) => {
  const selectedGuideIds = useMemo(() => selectedGuides.map(guide => guide.guideId), [selectedGuides]);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const requestBody = {
      searchText: searchText
    };
    
    axios.post(`${config.baseURL}/api/products/search`, requestBody)
    .then(response => {
    //   console.log(JSON.stringify(response.data, null, 2));
      setSearchResults(response.data);
    })
    .catch(error => {
      console.error('There was an error fetching the search results!', error);
    });
  }, [searchText, selectedGuideIds, onSelectProduct]);

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <div className="search-tab">
      <div className="search-bar-container">
        <input 
          type="text" 
          className="search-bar" 
          placeholder="Search..." 
          value={searchText} 
          onChange={(e) => setSearchText(e.target.value)} 
        />
        {searchText && (
          <img 
            src={clearIcon} 
            alt="Clear search" 
            className="clear-icon" 
            onClick={handleClearSearch} 
          />
        )}
      </div>
      <div className="grid-container">
        {searchResults.map(product => (
          <div
            key={product.productId}
            className="product-tile-wrapper"
            onClick={() => onSelectProduct(product.productId)}
            style={{ cursor: 'pointer' }}
          >
            <ProductTile product={product} isSelected={product.productId === selectedProductId} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchTab;
