import React, { useState, useCallback, useEffect } from 'react';
import './App.css';
import MenuBar from './menuBar/MenuBar';
// import MenuBarTop from './menu/menuBar/MenuBarTop';
// import MenuBarBottom from './menu/menuBar/MenuBarBottom';
import ProductPane from './product/productPane/ProductPane';
import Tab from './tab/Tab';
import { guides } from './menuBar/guideSelector/guideData';

import { LoadScript } from '@react-google-maps/api';

function App() {
    const [selectedTab, setSelectedTab] = useState('Top Picks');
	const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedGuides, setSelectedGuides] = useState([guides[0]]);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [isMuted, setIsMuted] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [refreshFavorites, setRefreshFavorites] = useState(false);
    // const [isProductPaneVisible, setIsProductPaneVisible] = useState(true);
	const [isProductPaneVisible, setIsProductPaneVisible] = useState(window.innerWidth > 1023);

    const handleRefreshFavorites = useCallback(() => {
        setRefreshFavorites((prev) => !prev);
    }, []);

    const handleSelectProduct = (productId) => {
        setSelectedProductId(productId);
        setIsProductPaneVisible(true);
        setIsPlaying(true);
    };

    const handleBackClick = () => { 
        setIsProductPaneVisible(false); 
        setIsPlaying(false);
    };

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		if (window.innerWidth <= 1024) {
	// 			handleBackClick();
	// 		}
	// 	}, 1000);
	// 	return () => clearTimeout(timer);
	// }, []);

    return (
		<LoadScript googleMapsApiKey="AIzaSyAFosV7aFGAb9dA3VY7Gvvz3MC0ks0szEI">
			<div className="app">
			<MenuBar
				selectedTab={selectedTab}
				setSelectedTab={setSelectedTab}
				selectedLocation={selectedLocation}
				setSelectedLocation={setSelectedLocation}
				selectedGuides={selectedGuides}
				setSelectedGuides={setSelectedGuides}
			/>
            {/* <MenuBarTop
                selectedGuides={selectedGuides}
                setSelectedGuides={setSelectedGuides}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            /> */}
            <Tab
                selectedTab={selectedTab}
                selectedProductId={selectedProductId}
                handleSelectProduct={handleSelectProduct}
                selectedGuides={selectedGuides}
                refreshFavorites={refreshFavorites}
				isProductPaneVisible={isProductPaneVisible}
            />
            <ProductPane
                selectedProductId={selectedProductId}
                selectedGuides={selectedGuides}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                isPlaying={isPlaying}
                handleRefreshFavorites={handleRefreshFavorites}
                handleBackClick={handleBackClick}
                isProductPaneVisible={isProductPaneVisible}
            />
            {/* <MenuBarBottom
                selectedGuides={selectedGuides}
                setSelectedGuides={setSelectedGuides}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
            /> */}
        	</div>
	  	</LoadScript>
    );
}

export default App;
