import React from 'react';
import { GoogleMap, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const Map = ({ latitude, longitude }) => {
	return (
		<div className="map">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={{ lat: latitude, lng: longitude }}
				zoom={15}
			>
				<MarkerF
					key={`${latitude}-${longitude}`}
					position={{ lat: latitude, lng: longitude }}
				/>
			</GoogleMap>
		</div>
	);
};

export default Map;
