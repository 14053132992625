import React, { useState } from 'react';

const LocationSelector = ({ selectedLocation, setSelectedLocation }) => {
	
	return (
		<div className="locationSelector">
			Austinˇ	
		</div>
	);
};

export default LocationSelector;
