import React from 'react';
import PropTypes from 'prop-types';
import './GuideGrid.css';
import { guides, guideImages } from '../guideData';

const GuideGrid = ({ selectedGuides, onSelectGuide, onClose }) => {
	const toggleGuideSelection = (guide) => {
		const isSelected = selectedGuides.some((g) => g.guideId === guide.guideId);
		if (isSelected) {
			if (selectedGuides.length > 1) {
				onSelectGuide(selectedGuides.filter((g) => g.guideId !== guide.guideId));
			}
		} else {
			if (selectedGuides.length < 3) {
				onSelectGuide([...selectedGuides, guide]);
			}
		}
	};
	
	return (
		<div className="guide-grid">
			<h3>Select 1-3 guides</h3>
			<div className="guides">
				{guides.map((guide) => (
					<div
					key={guide.guideId}
					className={`guide-item ${selectedGuides.some((g) => g.guideId === guide.guideId) ? 'selected' : ''}`}
					onClick={() => toggleGuideSelection(guide)}
					>
						<img src={guideImages[guide.code]} alt={guide.label} />
						<p>{guide.label}</p>
					</div>
				))}
				</div>
			<button className="explore-button" onClick={onClose}>Explore</button>
		</div>
	);
};

GuideGrid.propTypes = {
	selectedGuides: PropTypes.array.isRequired,
	onSelectGuide: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default GuideGrid;
