import React from 'react';
import VideoPlayer from '../../../common/videoPlayer/VideoPlayer';
import './MainMedia.css';
import ProductInfo from '../productInfo/ProductInfo';

function MainMedia({
  productDetail,
  isMuted,
  isPlaying,
  shouldReplay,
  setShouldReplay
}) {
	
  return (
    <div className="mainMedia">
		{productDetail.videoPath ? (

			<VideoPlayer
				videoPath={productDetail.videoPath}
				isMuted={isMuted}
				isPlaying={isPlaying}
				shouldReplay={shouldReplay}
				setShouldReplay={setShouldReplay}
			/>

		) : (
			<div className="wrapper">
				<img src={productDetail.verticalUrl} alt={productDetail.productName}/>
			</div>
		)}

		<ProductInfo productDetail={productDetail}/>
    </div>
  );
}

export default MainMedia;
