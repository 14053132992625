import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './GuideSelector.css';
import GuideGrid from './guideGrid/GuideGrid';
import { guideImages } from './guideData';

const GuideSelector = ({ selectedGuides, setSelectedGuides }) => {
	const [isGuideGridVisible, setGuideGridVisible] = useState(false);
	const [tempSelectedGuides, setTempSelectedGuides] = useState(selectedGuides); // Temporary state
	
	const toggleGuideGrid = () => {
		setGuideGridVisible(!isGuideGridVisible);
		setTempSelectedGuides(selectedGuides); // Reset temp state when toggling
	};
	
	return (
		<div className="guide-container">
		<div className="guide-selector" onClick={toggleGuideGrid}>
		{selectedGuides.map((guide, index) => (
			<img key={index} src={guideImages[guide.code]} alt={`Guide ${guide.label}`} className="guide-image" />
		))}
		</div>
		{isGuideGridVisible && (
			<GuideGrid
				selectedGuides={tempSelectedGuides}
				onSelectGuide={setTempSelectedGuides}
				onClose={() => {
					setGuideGridVisible(false);
					setSelectedGuides(tempSelectedGuides); // Update main state on close
				}}
			/>
		)}
		</div>
	);
};

GuideSelector.propTypes = {
	selectedGuides: PropTypes.array.isRequired,
	setSelectedGuides: PropTypes.func.isRequired,
};

export default GuideSelector;

// ------------- End of src/app/guideSelector/GuideSelector.js -------------
