import React, { useEffect, useState } from 'react';
import './ProductPane.css';
import ProductDetail from '../productDetail/ProductDetail';

function ProductPane({
  selectedProductId,
  selectedGuides,
  isMuted,
  setIsMuted,
  isPlaying,
  handleBackClick,
  isProductPaneVisible,
  isHidingBackButton,
  handleRefreshFavorites
}) {
  const [hidingBackButton, setHidingBackButton] = useState(isHidingBackButton);

  useEffect(() => {
    const updateLayout = () => {
      setProductPaneWidth();
      setHidingBackButton(window.innerWidth > 1023);
    };

    updateLayout();
    window.addEventListener('resize', updateLayout);

    console.log("ProductPane isProductPaneVisible:", isProductPaneVisible);

    return () => {
      window.removeEventListener('resize', updateLayout);
    };
  }, [isProductPaneVisible]);

  return (
    <div className={`product-pane ${isProductPaneVisible ? 'visible' : 'hidden'}`} id="productPane">
      <ProductDetail 
        productId={selectedProductId}
        selectedGuides={selectedGuides}
		isPlaying={isPlaying}
        handleBackClick={handleBackClick}
        isHidingBackButton={hidingBackButton} // Controlled by state
        handleRefreshFavorites={handleRefreshFavorites}
      />
    </div>
  );
}

function setProductPaneWidth() {
    const productPane = document.getElementById('productPane');
    const menuBar = document.getElementById('menuBar');
    const tab = document.getElementById('tab');
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    
    if (productPane && menuBar && tab) {
        if (viewportWidth > 1023) {
            const paneWidth = (viewportHeight * 9 / 16) - 80;
            const menubarWidth = viewportWidth - paneWidth;
            productPane.style.width = `${paneWidth}px`;
            menuBar.style.width = `${menubarWidth}px`;
            tab.style.width = `${menubarWidth - 10}px`;
        } else {
            productPane.style.width = '100%';
			menuBar.style.width = '100%';
            tab.style.width = '100%';
        }
    } else {
        console.error('One or more elements not found');
    }
}


export default ProductPane;
