import React from 'react';
import PropTypes from 'prop-types';
import './CircularGauge.css';

const CircularGauge = ({ value, size }) => {
  const strokeWidth = size * 0.2; // 10% of the size
  const radius = (size / 2) - (strokeWidth / 2);
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value * circumference);

  return (
    <svg className="circular-gauge" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        className="gauge-bg"
        // stroke="lightgray"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="gauge"
        // stroke="lightblue"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx={size / 2}
        cy={size / 2}
        style={{ strokeDasharray: `${circumference} ${circumference}`, strokeDashoffset: offset }}
      />
    </svg>
  );
};

CircularGauge.propTypes = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
};

export default CircularGauge;
