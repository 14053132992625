import React, { useMemo } from 'react';
import './Tab.css';
import FitTab from './fit/FitTab';
import FavoriteTab from './favorite/FavoriteTab';
import SearchTab from './search/SearchTab';

function Tab({ selectedTab, selectedProductId, handleSelectProduct, selectedGuides, refreshFavorites, isProductPaneVisible }) {

	const renderContent = useMemo(() => {
		switch (selectedTab) {
		  case 'Top Picks':
			return <FitTab onSelectProduct={handleSelectProduct} selectedGuides={selectedGuides} selectedProductId={selectedProductId} />;
		  case 'Favorites':
			return <FavoriteTab onSelectProduct={handleSelectProduct} selectedGuides={selectedGuides} selectedProductId={selectedProductId} refresh={refreshFavorites} />;
		  case 'Search':
			return <SearchTab onSelectProduct={handleSelectProduct} selectedGuides={selectedGuides} selectedProductId={selectedProductId} />;
		  default:
			return null;
		}
	  }, [selectedTab, selectedGuides, selectedProductId, refreshFavorites]);
	
	return (
		<div className={`tab ${isProductPaneVisible ? 'narrow' : ''}`} id="tab">
			<div className="tab-content">
				{renderContent}
			</div>

			{/* <div className="tab-content-placeholder">
				tab-content-placeholder
				<div className="buttons">
					<button onClick={() => setIsProductOverlayVisible(true)} style={{ marginTop: '200px' }}>Show Product Overlay</button>
					<button onClick={() => setIsProductOverlayVisible(true)} style={{ marginTop: '200px' }}>Show Product Overlay</button>
					<button onClick={() => setIsProductOverlayVisible(true)} style={{ marginTop: '200px' }}>Show Product Overlay</button>
				</div>
			</div> */}
		</div>
	);
}

export default Tab;
