import React from 'react';
import './Footnotes.css'

function Footnotes({ productDetail }) {
	return (
		<div className="productDetail-footnotes">
			<h2>Information</h2>
			
			<div className="footnotes-label">Neighborhood</div>
			<div className="footnotes-field">{productDetail.neighborhoodName}</div>
			<div className="footnotes-label">Phone number</div>
			<div className="footnotes-field">{productDetail.phone}</div>
			<div className="footnotes-label">Website</div>

			<div className="footnotes-field">
				<a href={productDetail.website} target="_blank" rel="noopener noreferrer">
					{productDetail.website}
				</a>
			</div>
		</div>
	);
}

export default Footnotes;
