import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './ProductDetail.css'
import config from '../../utility/config';

import ActionButtons from './actionButtons/ActionButtons';
import MainMedia from './mainMedia/MainMedia';
import ContactButtons from './contactButtons/ContactButtons';
import Map from '../../common/map/Map';
import Footnotes from './footnotes/Footnotes';

function ProductDetail({productId, selectedGuides, isPlaying, handleBackClick, isHidingBackButton, handleRefreshFavorites}) {

	const [isMuted, setIsMuted] = useState(false)
	// const [isPlaying, setIsPlaying] = useState(true)
	const [shouldReplay, setShouldReplay] = useState(false);

	const toggleIsMuted = () => {
        setIsMuted(!isMuted);
    };

/* PRODUCT DETAIL */
	const [productDetail, setProductDetail] = useState({});
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		let isMounted = true;
		
		const fetchProductDetail = async () => {
			try {
				if (isMounted) {
					setIsLoading(true);
					
					const selectedGuideIds = selectedGuides.map((guide) => guide.guideId);
					const requestBody = { guideIds: selectedGuideIds };
					const productResponse = await axios.post(`${config.baseURL}/api/products/${productId}/detail`, requestBody);
					const favoriteResponse = await axios.get(`${config.baseURL}/api/favorites/${productId}`);
					
					setProductDetail(productResponse.data);
					setIsFavorite(favoriteResponse.data.isFavorited === 1);
					
					setIsLoading(false);
				}
			} catch (error) {
				console.error('There was an error fetching the product detail!', error);
				if (isMounted) {
					setIsLoading(false);
				}
			}
		};
		
		fetchProductDetail();
		
		return () => {
			isMounted = false;
		};
	}, [productId, selectedGuides]);

/* FAVORITE */

	const [isFavorite, setIsFavorite] = useState(false)

	const toggleIsFavorite = async () => {
		setIsFavorite((prevFavorite) => !prevFavorite);
	
		try {
			await axios.post(`${config.baseURL}/api/favorites/toggle`, { productId });
			const favoriteResponse = await axios.get(`${config.baseURL}/api/favorites/${productId}`);
			setIsFavorite(favoriteResponse.data.isFavorited === 1);
			handleRefreshFavorites()
		} catch (error) {
			console.error('There was an error toggling the favorite status!', error);
			setIsFavorite((prevFavorite) => !prevFavorite);
		}
	};

/* HANDLERS */

	function backClick() {
		// setIsPlaying(false)
		handleBackClick()
	}

    return (
		<div className="productDetail">
			<ActionButtons
				handleBackClick={backClick}
				isHidingBackButton={isHidingBackButton}
				isVideo={productDetail.videoPath}
				shouldReplay={shouldReplay}
				setShouldReplay={setShouldReplay}
				isMuted={isMuted}
				toggleIsMuted={toggleIsMuted} 
				isFavorite={isFavorite}
				toggleIsFavorite={toggleIsFavorite}
			/>

			<MainMedia
				productDetail={productDetail}
				isMuted={isMuted}
				isPlaying={isPlaying}
				shouldReplay={shouldReplay}
				setShouldReplay={setShouldReplay}
			/>

			<ContactButtons productDetail={productDetail} />

			<Map latitude={productDetail.latitude} longitude={productDetail.longitude} />

			<Footnotes productDetail={productDetail} /> 

		</div>
    );
}

export default ProductDetail;
