import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import config from '../../utility/config';  
import './FitTab.css';
import ProductStrip from '../../product/productStrip/ProductStrip';

const FitTab = ({ selectedGuides, onSelectProduct, selectedProductId }) => {
  const selectedGuideIds = useMemo(() => selectedGuides.map(guide => guide.guideId), [selectedGuides]);
  const [fitResults, setFitResults] = useState({});

  useEffect(() => {
    const requestBody = {
      guideIds: selectedGuideIds.length > 0 ? selectedGuideIds : [1],
      locationId: 28,
      minimumFit: 0.0
    };
    
    axios.post(`${config.baseURL}/api/tabs/home`, requestBody)
    .then(response => {
	// 	console.log("requestBody:", requestBody)
    //   console.log("response.data:", JSON.stringify(response.data, null, 2));
      setFitResults(response.data);

    //   Automatically select the first product in the Entertainment strip if none is selected
      if (!selectedProductId && response.data.entertainment && response.data.entertainment.length > 0) {
        // onSelectProduct(response.data.entertainment[0].productId);

		if (window.innerWidth > 1023) {
			onSelectProduct(response.data.entertainment[0].productId);
		  }
      }
    })
    .catch(error => {
      console.error('There was an error fetching the home tab!', error);
    });
  }, [selectedGuideIds, selectedProductId, onSelectProduct]);

  return (
    <div className="fit-tab">
      <ul className="no-bullets">
        <li><ProductStrip label="Entertainment" products={fitResults.entertainment} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} /></li>
        <li><ProductStrip label="Food" products={fitResults.food} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} /></li>
        <li><ProductStrip label="Bars" products={fitResults.bars} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} /></li>
        <li><ProductStrip label="Shopping" products={fitResults.shopping} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} /></li>
        <li><ProductStrip label="Hotels & lodging" products={fitResults.hotels} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} /></li>
        <li><ProductStrip label="Fitness" products={fitResults.fitness} onSelectProduct={onSelectProduct} selectedProductId={selectedProductId} /></li>
      </ul>
    </div>
  );
};

export default FitTab;
