import React from 'react';
import './ContactButtons.css'

import map from './images/map.png';
import phone from './images/phone.png';
import globe from './images/globe.png';
import pin from './images/pin.png';
import ellipsis from './images/ellipsis.png';

function ContactButtons({ productDetail }) {
	return (
		<div className="contactButtons">
			<button className="contactButton" onClick={() => window.open(productDetail.appleUrl, '_blank')}>
				<img src={map} alt="Map" />
				Apple
			</button>
			
			<button className="contactButton" onClick={() => (window.location.href = `tel:${productDetail.phone}`)}>
				<img src={phone} alt="Call" />
				Call
			</button>
			
			<button className="contactButton" onClick={() => window.open(productDetail.website, '_blank')}>
				<img src={globe} alt="Website" />
				Website
			</button>
			
			<button className="contactButton" onClick={() => window.open(productDetail.googleUrl, '_blank')}>
				<img src={pin} alt="Google" />
				Google
			</button>
			
			<button className="contactButton" disabled>
				<img src={ellipsis} alt="More" className="disabled-icon" />
				More
			</button>
		</div>
	);
}

export default ContactButtons;
