// src/products/ProductStrip.js

import React from 'react';
import ProductTile from '../productTile/ProductTile';
import './ProductStrip.css';

const ProductStrip = ({ label, products, onSelectProduct, selectedProductId }) => {
	if (!products || products.length === 0) return null;
	
	const handleProductClick = (product) => {
		onSelectProduct(product.productId);
	};
	
	return (
		<div className="product-strip">
			<h1>{label}</h1>
			<div className="product-list">
				{products.map((product, index) => (
					<div
					key={index}
					className="product-tile-wrapper"
					onClick={() => handleProductClick(product)}
					style={{ cursor: 'pointer' }}
					>
						<ProductTile product={product} isSelected={product.productId === selectedProductId} />
					</div>
				))}
				<div className="product-tile-spacer"></div>
			</div>
		</div>
	);
};

export default ProductStrip;
