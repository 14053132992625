import React from 'react';
import PropTypes from 'prop-types';
import './TabSelector.css';

import accountActive from './images/orange/account.png';
import favoritesActive from './images/orange/favorites.png';
import searchActive from './images/orange/search.png';
import topPicksActive from './images/orange/top-picks.png';
import tripsActive from './images/orange/trips.png';

import account from './images/gray/account.png';
import favorites from './images/gray/favorites.png';
import search from './images/gray/search.png';
import topPicks from './images/gray/top-picks.png';
import trips from './images/gray/trips.png';

const TabSelector = ({ selectedTab, setSelectedTab }) => {
	const tabs = [
		{ name: 'Top Picks', activeImage: topPicksActive, inactiveImage: topPicks },
		{ name: 'Favorites', activeImage: favoritesActive, inactiveImage: favorites },
		{ name: 'Trips', activeImage: tripsActive, inactiveImage: trips },
		{ name: 'Search', activeImage: searchActive, inactiveImage: search },
		{ name: 'Account', activeImage: accountActive, inactiveImage: account }
	];
	
	return (
		<div className="tab-selector">
			{tabs.map((tab) => (
				<div
					key={tab.name}
					className={`tab-button ${selectedTab === tab.name ? 'selected' : ''}`}
					onClick={() => setSelectedTab(tab.name)}
				>
					<img
						src={selectedTab === tab.name ? tab.activeImage : tab.inactiveImage}
						alt={tab.name}
						className="tab-image"
					/>
				</div>
			))}
		</div>
	);
};

TabSelector.propTypes = {
	selectedTab: PropTypes.string.isRequired,
	setSelectedTab: PropTypes.func.isRequired,
};

export default TabSelector;
