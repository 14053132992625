import React, {useCallback} from 'react';
import './ActionButtons.css';

import back from './images/back.png';
import replay from './images/replay.png';
import speaker from './images/speaker.png';
import speakerSlash from './images/speaker-slash.png';
import heart from './images/heart.png';
import heartFill from './images/heart-fill.png';

function ActionButtons({ handleBackClick, isHidingBackButton, isVideo, setShouldReplay, isMuted, toggleIsMuted, isFavorite, toggleIsFavorite }) {

	const handleReplay = useCallback(() => {
        setShouldReplay(true);
        setTimeout(() => setShouldReplay(false), 100);
    }, [setShouldReplay]);

	return (
		<div className="actionButtons">
			<div className="leftButtons">
				<button onClick={handleBackClick} className={isHidingBackButton ? 'hidden' : ''}>
					<img src={back} alt="Back" />
				</button>
			</div>
			<div className="rightButtons">
				{isVideo && 
				<div className="videoButtons">
					<button onClick={handleReplay}>
						<img src={replay} alt="Replay video" />
					</button>
					<button onClick={toggleIsMuted}>
						<img src={isMuted ? speakerSlash : speaker} alt="Toggle mute" />
					</button>
				</div>
				}
				<button onClick={toggleIsFavorite}>
					<img src={isFavorite ? heartFill : heart} alt="Favorite" />
				</button>
			</div>
		</div>
	);
}

export default ActionButtons;
