import React from 'react';
import CircularGauge from '../../common/circularGauge/CircularGauge';
import './ProductTile.css';
import checkmark from './images/checkmark.png';

const ProductTile = ({ product, isSelected }) => {
  return (
    <div className="product-tile">
      <img src={product.horizontalUrl} alt={product.productName} className="product-tile-image" />
      {isSelected && (
        <div className="checkmark">
          <img src={checkmark} alt="Selected" />
        </div>
      )}
      <div className="product-info">
        <div className="product-info-header">
          <h3>{product.productName}</h3>
          {product.fit && <CircularGauge value={product.fit} size={12} />}
        </div>
        <p>{product.label}</p>
      </div>
    </div>
  );
};

export default ProductTile;
