import React, { useEffect, useState, useRef } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../utility/firebase';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

const VideoPlayer = ({ videoPath, isPlaying, isMuted, shouldReplay, setShouldReplay }) => {
    const [videoUrl, setVideoUrl] = useState('');
    const playerRef = useRef(null);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const cachedUrl = localStorage.getItem(videoPath);
                if (cachedUrl) {
                    console.log(`Using cached URL for videoPath ${videoPath}: ${cachedUrl}`);
                    setVideoUrl(cachedUrl);
                } else {
                    const videoRef = ref(storage, videoPath);
                    const url = await getDownloadURL(videoRef);
                    localStorage.setItem(videoPath, url);
                    setVideoUrl(url);
                    console.log(`Fetched and cached URL for videoPath ${videoPath}: ${url}`);
                }
            } catch (error) {
                console.error('Error fetching video URL:', error);
            }
        };

        if (videoPath) {
            fetchVideoUrl();
        }

        return () => {
            URL.revokeObjectURL(videoUrl);
        };
    }, [videoPath, videoUrl]);

	useEffect(() => {
		if (shouldReplay) {
			if (playerRef.current) {
				playerRef.current.seekTo(0);
			}
			setShouldReplay(false)
		}
	}, [shouldReplay, setShouldReplay]);

    return (
        <div className="videoPlayer">
            <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                playing={isPlaying}
                muted={isMuted}
                width="100%"
                height="100%"
                className="react-player"
				playsinline
            />
            {/* <div className="button-group">
                <button className="replay-button" onClick={handleReplay}>
                    <img src={replayArrow} alt="Replay" />
                </button>
                <button className="mute-button" onClick={toggleMute}>
                    <img src={isMuted ? speakerSlash : speaker} alt={isMuted ? 'Unmute' : 'Mute'} />
                </button>
            </div> */}
        </div>
    );
};

export default VideoPlayer;
